import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const SearchbarDropdown = (props) => {
  const history = useNavigate();
  const { options, onInputChange, locationMap } = props;
  const ulRef = useRef();
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.addEventListener("click", (event) => {
      event.stopPropagation();
      ulRef.current.style.display = "flex";
      onInputChange(event);
    });
  }, [onInputChange]);
  return (
    <div className="search-bar-dropdown">
      <div className="search-input-container">
        <input
          id="search-bar"
          type="text"
          className="form-control"
          placeholder="Search All Dashboards"
          ref={inputRef}
          onChange={onInputChange}
          autoComplete="off"
        />
        <i className="fas fa-search search-icon"></i>
      </div>

      <ul
        id="results"
        className="list-group"
        ref={ulRef}
        style={{ position: "absolute", zIndex: 1000 }}
      >
        {options.map((option, _index) => {
          return (
            <button
              type="button"
              key={option}
              onClick={(e) => {
                inputRef.current.value = option;
                setTimeout(() => {
                  console.log(locationMap);
                  if (option !== null) {
                    history(
                      `/dashboards/${locationMap[inputRef.current.value]}`
                    );
                    window.location.reload(null);
                  }
                }, 100);
              }}
              className="list-group-item list-group-item-action"
            >
              {option}
            </button>
          );
        })}
      </ul>
    </div>
  );
};

export default SearchbarDropdown;
