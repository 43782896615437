import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useMsal } from "@azure/msal-react";
import Nav from "react-bootstrap/Nav";
import { useContext } from "react";
import { DashboardContext } from "../context/DashboardContext";

function CanvasNavbar() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const name = activeAccount?.name || "User";

  const { welcome } = useContext(DashboardContext);

  return (
    <>
      {["sm"].map((expand) => (
        <Navbar
          sticky="top"
          key={expand}
          expand={expand}
          className=" pretty-navbar"
          style={{ height: "70px" }}
        >
          <Container fluid>
            <Navbar.Brand href="/">
              <img
                src="/transparent.png"
                alt="Logo"
                style={{
                  marginRight: "10px", // Adjust the value to control the spacing between the logo and the text
                  width: "80px", // Set the width of the logo
                  height: "80px", // Set the height of the logo
                  verticalAlign: "middle", // Align the logo vertically with the text
                  position: "relative",
                  top: "5px", // Move the logo slightly lower
                }}
              />
              <div
                style={{
                  display: "inline-flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginLeft: "0px",
                }}
              >
                <span
                  style={{
                    color: "#EB0A1E",
                    fontWeight: "bold",
                    lineHeight: "0.8",
                  }}
                >
                  GAP
                </span>
                <div style={{ lineHeight: "0.8" }}>Showcase</div>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  GAP Showcase
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {!welcome && (
                  <Nav className="justify-content-end flex-grow-1 px-md-5 pt-2">
                    <Nav.Link className="btn-group" href="/">
                      <button className="btn btn-danger welcome-button my-3">
                        Home
                      </button>
                    </Nav.Link>
                  </Nav>
                )}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <Form className="search"></Form>
            <div
              className="dropdown"
              style={{
                marginRight: "20px", // Adjust the value to control the spacing between the logo and the text
                verticalAlign: "middle", // Align the logo vertically with the text
                position: "relative",
                top: "5px", // Move the logo slightly lower
              }}
            >
              <a
                href="/"
                className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle"
                id="dropdownUser2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i
                  className="far fa-user me-2"
                  style={{ color: "#ff0000", fontSize: "20px" }}
                ></i>
                <strong>{name}</strong>
              </a>
            </div>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default CanvasNavbar;
