import React, { useState, createContext } from "react";

export const DashboardContext = createContext();

export const DashboardContextProvider = (props) => {
  const [dashboards, setDashboards] = useState([]);
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [location, setLocation] = useState(null);
  const [welcome, setWelcome] = useState(true);
  const [showCategories, setShowCategories] = useState(false);

  const addDashboards = (dashboard) => {
    console.log(dashboards);
    if (Array.isArray(dashboards) && dashboards.length > 0) {
      setDashboards([...dashboards, dashboard]);
    } else {
      setDashboards([dashboard]);
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        dashboards,
        setDashboards,
        addDashboards,
        selectedDashboard,
        setSelectedDashboard,
        location,
        setLocation,
        welcome,
        setWelcome,
        showCategories,
        setShowCategories,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
