import React from 'react'
import UpdateDashboard from '../components/UpdateDashboard'

const UpdatePage = () => {
  return (
    <div>
    <h1 className='text-center'>Update Dashboard</h1>
    <UpdateDashboard/>
    </div>
  );
};

export default UpdatePage