import { useContext, useEffect, useState } from "react";
import DashboardFinder from "../apis/DashboardFinder";
import { DashboardContext } from "../context/DashboardContext";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import SearchbarDropdown from "./SearchbarDropdown";

const DisplayDashboards = () => {
  const { location } = useContext(DashboardContext);
  const { dashboards, setDashboards } = useContext(DashboardContext);

  const [selectedId, setSelectedId] = useState(null);

  const [searchResults, setSearchResults] = useState([]);
  const arr = [];
  const [locationMap, setLocationMap] = useState({});
  let lmap = {};

  const handleSearch = async (event) => {
    const search_query = event.target.value;
    DashboardFinder.get("/search", { params: { search_query: search_query } })
      .then((res) => {
        console.log(res?.data?.data?.rows);
        res?.data?.data?.rows.forEach((value, _index) => {
          arr.push(value.name);
          lmap[value.name] = value.id;
          setLocationMap(lmap);
          console.log(locationMap);
        });
        setSearchResults(arr);
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log("search results", searchResults);
  };

  let history = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await DashboardFinder.get(`/group/${location}`);
        setDashboards(response.data.data.dashboard);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [location, setDashboards]);

  const handleDashboardSelect = (id) => {
    setSelectedId(id);
    setTimeout(() => {
      history(`/dashboards/${id}`);
    }, 100); // Adjust the delay time (in milliseconds) to match your animation duration
  };

  const imageSrc = (selectedDashboard) => {
    const baseURL = `api/images/${selectedDashboard.location}/${selectedDashboard.name}.png`;
    return process.env.NODE_ENV === "production" ? baseURL : `/api/${baseURL}`;
  };

  return (
    <span>
      {dashboards ? (
        <div className="album py-3">
          <div className="container-fluid">
            <div className="d-flex justify-content-between ">
              <h2 className="text-left pb-3" style={{ marginLeft: "6px" }}>
                {location} Dashboards
              </h2>
              <div
                className=" d-flex justify-content-center"
                style={{ marginRight: "4px" }}
              >
                <SearchbarDropdown
                  onInputChange={handleSearch}
                  options={searchResults}
                  locationMap={locationMap}
                />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3">
              {location != null &&
                dashboards &&
                dashboards.map((el) => {
                  return (
                    <motion.div
                      className="col-12 col-md-6 col-lg-3" // Adjust col classes for card width
                      onClick={() => handleDashboardSelect(el.id)}
                      key={el.name}
                      animate={{ scale: selectedId === el.id ? 0.9 : 1 }}
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 20,
                      }}
                    >
                      <motion.div className="card shadow-sm m-1">
                        <div className="p-1">
                          <img
                            src={imageSrc(el)}
                            alt={el.name}
                            className="card-img-top img-thumbnail"
                          />
                        </div>
                        <motion.div className="card-body">
                          <h5 className="card-title">{el.name}</h5>
                          <p className="card-text">{el.details}</p>
                          <motion.div className="d-flex justify-content-between align-items-center">
                            <motion.div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-sm card-button"
                              >
                                View
                              </button>
                            </motion.div>
                          </motion.div>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  );
                })}
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <div
            className="spinner-border m-5"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </span>
  );
};

export default DisplayDashboards;
