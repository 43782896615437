import React, { useState, useEffect, useContext } from "react";
//components
import AddDashboards from "../components/AddDashboards";
import AllDashboardsList from "../components/AllDashboardsList";
import DisplayDashboards from "../components/DisplayDashboards";
//azure
import { useMsal, AuthenticatedTemplate } from "@azure/msal-react";
import WelcomeScreen from "../components/WelcomeScreen";
import { DashboardContext } from "../context/DashboardContext";

const Main = (param) => {
  const { showCategories, setShowCategories } = useContext(DashboardContext);
  const { location, setLocation } = useContext(DashboardContext);
  const { welcome, setWelcome } = useContext(DashboardContext);

  function toggleCategories() {
    setShowCategories(!showCategories);
    toggleWelcome();
    setLocation("ADR");

    console.log(location);
  }

  function toggleWelcome() {
    if (welcome) {
      setWelcome(!welcome);
    }
  }

  return (
    <div>
      {welcome ? (
        <WelcomeScreen toggleCategories={toggleCategories} />
      ) : (
        showCategories && <DisplayDashboards />
      )}
      {param.admin ? (
        <div className="admin-main">
          <AllDashboardsList />
          <AddDashboards />
        </div>
      ) : null}
    </div>
  );
};

const Home = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    if (activeAccount) {
      const roles = activeAccount?.idTokenClaims?.roles;
      const isAdmin = roles.includes("Dashboard.Admin");
      console.log("Account", activeAccount);
      console.log("Name", activeAccount?.name);
      console.log("Roles", roles);
      console.log("Check Admin", isAdmin);

      if (isAdmin) {
        setAdmin(true);
      }
    }
  }, [activeAccount]);

  return (
    <div id="Home">
      <AuthenticatedTemplate>
        {activeAccount ? <Main admin={admin} /> : null}
      </AuthenticatedTemplate>
    </div>
  );
};

export default Home;
