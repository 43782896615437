import React from "react";

function WelcomeScreen({ toggleCategories }) {
  return (
    <section className="jumbotron text-center container-fluid full-screen">
      <div className="content-wrapper">
        <h1 className="jumbotron-heading">
          <strong>Welcome!</strong>
        </h1>
        <p className="lead">
          Welcome to <strong>GAP Analytics</strong>, the go-to website for{" "}
          <strong>Toyota</strong> employees to <br />
          access insightful dashboards and make data-driven decisions.
        </p>
        <div className="category-container">
          <button
            className="btn btn-danger welcome-button my-3"
            onClick={toggleCategories}
          >
            Select Project
          </button>
        </div>
      </div>
    </section>
  );
}

export default WelcomeScreen;
