import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DashboardContext } from "../context/DashboardContext";
import AddTicket from "../components/AddTicket";
import DashboardFinder from "../apis/DashboardFinder";

const DashboardDetailPage = () => {
  const { id } = useParams();

  const { selectedDashboard, setSelectedDashboard } =
    useContext(DashboardContext);
  const [activeTab, setActiveTab] = useState("details");
  function handleClick(tab) {
    setActiveTab(tab);
  }

  const imageSrc = (selectedDashboard) => {
    const baseURL = `api/images/${selectedDashboard.dashboard.location}/${selectedDashboard.dashboard.name}.png`;
    return process.env.NODE_ENV === "production" ? `/${baseURL}` : `/api/${baseURL}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await DashboardFinder.get(`/${id}`);
        console.log(response);

        setSelectedDashboard(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [id, setSelectedDashboard]);

  return (
    <span>
      {selectedDashboard && (
        <>
          <h2 className="text-center display-1">
            {selectedDashboard.dashboard.name}
          </h2>
          <div className="container bg-light">
            <br />
            <figure style={{ textAlign: "center" }}>
              <img
                style={{ maxWidth: "100%", borderRadius: "0.25rem" }}
                src={imageSrc(selectedDashboard)}
                alt={selectedDashboard.dashboard.name}
              />
              <br />
            </figure>

            <div className="d-flex justify-content-between align-items-center">
              <h4 className="text-left">About this dashboard:</h4>

              <div>
                <button
                  onClick={() => handleClick("details")}
                  className={
                    activeTab === "details"
                      ? "btn  cat-button btn-dark mr-1"
                      : "btn  cat-button btn-outline-dark mr-1"
                  }
                >
                  Details
                </button>
                <button
                  onClick={() => handleClick("catalog")}
                  className={
                    activeTab === "catalog"
                      ? "btn    btn-dark cat-button"
                      : "btn  cat-button btn-outline-dark"
                  }
                >
                  Catalog
                </button>
              </div>
            </div>
            <hr style={{ height: "1px", backgroundColor: "#D9D9D9" }}></hr>

            {activeTab === "details" && (
              <div>
                <p>{selectedDashboard.dashboard.details}</p>
              </div>
            )}
            {activeTab === "catalog" && (
              <div>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Sample Field</th>
                      <th>Sample Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(selectedDashboard.dashboard.samples).map(
                      ([key, value]) => (
                        <tr key={key}>
                          <td>{key}</td>
                          <td>
                            {typeof value === "object"
                              ? JSON.stringify(value)
                              : value}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            )}
            <hr style={{ height: "1px", backgroundColor: "#D9D9D9" }}></hr>
          </div>
          <br />

          <AddTicket
            name={selectedDashboard.dashboard.name}
            location={selectedDashboard.dashboard.location}
          />
        </>
      )}
    </span>
  );
};

export default DashboardDetailPage;
