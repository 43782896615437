import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const ImageUpload = ({ onUpload, preview, setPreview }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      onUpload(file);
      setPreview(URL.createObjectURL(file));
    },
    [onUpload, setPreview]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleImageError = () => {
    setPreview(null);
  };

  return (
    <div className="image-upload-container">
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <i className="fas fa-cloud-upload-alt fa-2x"></i>
        {isDragActive ? (
          <p>Drop the image here...</p>
        ) : (
          <p>
            Drag and drop an image here, or click to select a file from your
            computer.
          </p>
        )}
        {preview && (
          <div className="image-preview-container">
            <img
              src={preview}
              alt="Preview"
              className="image-preview"
              onError={handleImageError}
            />
            <button onClick={() => handleImageError()}>Delete</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
