import React, { useState, useContext } from "react";
import { DashboardContext } from "../context/DashboardContext";

const Sidebar = () => {
  const categories = [
    "ADR",
    "Archive",
    "Digital Key",
    "EV",
    "Hide Card Dashboard",
    "Login Analytics",
    "OneApp",
    "Parking",
    "Product Metrics",
    "Remote Commands",
    "Rental Analytics",
    "SSR",
    "TFS",
    "21MM HU Version Usage",
  ];

  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const { setLocation } = useContext(DashboardContext);

  const handleClick = (category) => {
    setSelectedCategory(category);
    setLocation(category);
  };

  return (
    <div>
      {categories.map((category, index) => (
        <div
          key={JSON.stringify(index)}
          className={`category${
            selectedCategory === category ? " selected" : ""
          }`}
          onClick={() => handleClick(category)}
        >
          <div className="category-name">{category}</div>
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
