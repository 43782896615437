import React, { useEffect, useContext } from "react";
import { DashboardContext } from "../context/DashboardContext";
import { useNavigate } from "react-router-dom";
import DashboardFinder from "../apis/DashboardFinder";

const AllDashboardsList = (props) => {
  const { dashboards, setDashboards } = useContext(DashboardContext);

  let history = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await DashboardFinder.get();
        console.log("dashboards", response);
        setDashboards(response.data.data.dashboard);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [setDashboards]);

  const handleUpdate = (e, id) => {
    e.stopPropagation();
    history(`/dashboards/${id}/update`);
  };

  const handleDashboardSelect = (id) => {
    history(`/dashboards/${id}`);
  };

  const handleDelete = async (e, id, name, location) => {
    e.stopPropagation();
    try {
      await DashboardFinder.delete(`/${id}/${location}/${name}`);
      setDashboards(
        dashboards.filter((dashboard) => {
          return dashboard.id !== id;
        })
      );
    } catch (err) {
      // Handle error
    }
  };

  const renderRating = (dashboard) => {
    if (!dashboard.count) {
      return <span className="text-warning">0 comments</span>;
    }
    return (
      <>
        <span className="text-warning ml-1">({dashboard.count})</span>
      </>
    );
  };

  return (
    <div className="list-group">
      <table className="table table-hover">
        <thead className="table-dark">
          <tr className="bg-primary">
            <th scope="col">Name</th>
            <th scope="col">Project</th>
            <th scope="col">Source</th>
            <th scope="col">Comments</th>
            <th scope="col">Edit</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: "transparent" }}>
          {dashboards && dashboards.length > 0 ? (
            dashboards.map((dashboard) => (
              <tr
                onClick={() => handleDashboardSelect(dashboard.id)}
                key={dashboard.id}
              >
                <td>{dashboard.name}</td>
                <td>{dashboard.location}</td>
                <td>{dashboard.stack}</td>
                <td>{renderRating(dashboard)}</td>
                <td>
                  <button
                    onClick={(e) => handleUpdate(e, dashboard.id)}
                    className="btn btn-warning"
                  >
                    Update
                  </button>
                </td>
                <td>
                  <button
                    onClick={(e) =>
                      handleDelete(
                        e,
                        dashboard.id,
                        dashboard.name,
                        dashboard.location
                      )
                    }
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "200px" }}
                >
                  <div
                    className="alert alert-warning text-center"
                    style={{
                      width: "300px",
                      fontSize: "20px",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    No Session Dashboards
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default AllDashboardsList;
