import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
//import DashboardFinder from "../apis/DashboardFinder";

const AddTicket = (props) => {
  //create body data per each parameter
  const [email, setEmail] = useState("");
  const [workID, setWorkID] = useState("");
  const [mailTo, setMailTo] = useState("");

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const createTicket = await DashboardFinder.post(`/createIssue`, {
  //     email,
  //     workID,
  //     reason,
  //     name: props.name,
  //     location: props.location,
  //   });

  //   console.log("Ticket Creation", createTicket);
  // };

  useEffect(() => {
    if (activeAccount) {
      const currentEmail = activeAccount?.username;
      const currentWorkdayID = activeAccount?.idTokenClaims?.workdayId;

      console.log(currentEmail, currentWorkdayID);

      setEmail(currentEmail);
      setWorkID(currentWorkdayID);

      const subject = encodeURIComponent(
        `${currentWorkdayID} is requesting a ${props.name} dashboard.`
      );
      const body = encodeURIComponent(
        `Good Day! ${currentEmail} with Work Id - ${currentWorkdayID} is requesting a ${props.name} dashboard.`
      );
      const mailToURL = `mailto:swati.ghosh@toyota.com, james.banasiak@toyota.com, paul.ramireddy@toyota.com, ravi.chiripurapu@toyota.com, adharsh.jayasree@toyota.com?subject=${subject}&body=${body}!`;
      setMailTo(mailToURL);
    }
  }, [activeAccount, props.name]);

  return (
    <div className="container my-5">
      <div className="row ">
        <div className="col-lg-9">
          <h1 className="mb-3">Request Dashboard</h1>
          <form>
            <div className="row g-3">
              <div className="col-md-6">
                <label className="form-label">Your Email</label>
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  defaultValue={email}
                  id="email"
                  name="your-email"
                  required
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Workday ID</label>
                <input
                  type="text"
                  onChange={(e) => setWorkID(e.target.value)}
                  className="form-control"
                  defaultValue={workID}
                  id="workID"
                  name="your-id"
                />
              </div>
              {/* <div className="col-12">
                <label className="form-label">Your Business Case</label>
                <textarea
                  className="form-control"
                  onChange={(e) => setReason(e.target.value)}
                  id="reason"
                  name="your-reason"
                  rows="5"
                  required
                ></textarea>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-md-6">
                    <button
                      className="btn btn-danger welcome-button w-100 fw-bold"
                      id="createIssue"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div> */}
              <div className="col-12">
                <a href={mailTo}>
                  Click here to Send an email requesting the dashboard
                </a>
              </div>
              <div className="col-12">
                <p>Points of Contact</p>
                <ul>
                  <li>Swati Ghosh - swati.ghosh@toyota.com</li>
                  <li>Ravi Chiripurapu - ravi.chiripurapu@toyota.com</li>
                  <li>James Banasiak - james.banasiak@toyota.com</li>
                  <li>Paul Ramireddy - paul.ramireddy@toyota.com</li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTicket;
