import { LogLevel } from "@azure/msal-browser";

//MSAL
export const msalConfig = {
  auth: {
    clientId: "aba4219a-7482-47da-a20a-05ba4ed0c651", // Replace with your app's client ID from Azure AD
    authority:
      "https://login.microsoftonline.com/8c642d1d-d709-47b0-ab10-080af10798fb", // Replace with your Azure AD tenant ID
  },

  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
};

export const appRoles = {
  User: "Dashboard.Read",
  Admin: "Dashboard.Admin",
};
