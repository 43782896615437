import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import {
  DashboardContextProvider,
  DashboardContext,
} from "./context/DashboardContext";
import Home from "./routes/Home";
import DashboardDetailPage from "./routes/DashboardDetailPage";
import UpdatePage from "./routes/UpdatePage";
import CanvasNavbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import "./App.css";

function Content() {
  const { welcome } = useContext(DashboardContext);
  const { selectedDashboard } = useContext(DashboardContext);

  return (
    <>
      <CanvasNavbar />
      <div className="container-fluid">
        <div className="row">
          {!welcome && !selectedDashboard && (
            <div className="col-auto sidebar">
              <Sidebar />
            </div>
          )}
          <div
            className={`col${!welcome ? "" : "-12"}`}
            style={{ backgroundColor: "#F8F9FA" }}
          >
            <div id="home" className="home">
              <Routes>
                <Route path="/" exact element={<Home />} />
                <Route
                  path="/dashboards/:id/update"
                  exact
                  element={<UpdatePage />}
                />
                <Route
                  path="/dashboards/:id/"
                  exact
                  element={<DashboardDetailPage />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Main() {
  return (
    <DashboardContextProvider>
      <Content />
    </DashboardContextProvider>
  );
}

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Main instance={instance} />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

export default App;
