import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ImageUpload from "./ImageUpload";
import DashboardFinder from "../apis/DashboardFinder";

const UpdateDashboard = (props) => {
  const { id } = useParams();
  let history = useNavigate();
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [stack, setStack] = useState("");
  const [tags, setTags] = useState("");
  const [details, setDetails] = useState("");
  const [samples, setSamples] = useState("");
  const [source, setSource] = useState("");
  const [imageFiles, setImageFiles] = useState([
    `/api/images/${location}/${name}.png`,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await DashboardFinder.get(`/${id}`);
      console.log(response.data.data);
      setName(response.data.data.dashboard.name);
      setLocation(response.data.data.dashboard.location);
      setStack(response.data.data.dashboard.stack);
      setTags(response.data.data.dashboard.tags);
      setDetails(response.data.data.dashboard.details);
      setSource(response.data.data.dashboard.source);
      setSamples(JSON.stringify(response.data.data.dashboard.samples));
    };

    fetchData();
  }, [id]);

  const handleImageUpload = (e) => {
    setImageFiles(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("trying to add...");

    const tagsArray = tags
      .toString()
      .split(",")
      .map((tag) => tag.trim());
    const tagsArrayLiteral =
      "{" +
      tagsArray.map((tag) => '"' + tag.replace(/"/g, '""') + '"').join(",") +
      "}";

    const formData = new FormData();
    formData.append("images", imageFiles);
    formData.append("name", name);
    formData.append("location", location);
    formData.append("stack", stack);
    formData.append("tags", tagsArrayLiteral);
    formData.append("details", details);
    formData.append("source", source);
    formData.append("samples", samples);

    try {
      const response = await DashboardFinder.put(`/${id}`, formData);
      const data = await response.data;

      console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    }
    history(`/dashboards/${id}`);
  };

  return (
    <div>
      <ImageUpload
        onUpload={handleImageUpload}
        location={location}
        name={name}
      />

      <form action="">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="name"
            className="form-control"
            type="text"
          />
        </div>

        <div className="form-group">
          <label htmlFor="location">Project</label>
          <input
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            id="location"
            className="form-control"
            type="text"
          />
        </div>

        <div className="form-group">
          <label htmlFor="stack">Stack</label>
          <input
            value={stack}
            onChange={(e) => setStack(e.target.value)}
            id="stack"
            className="form-control"
            type="text"
          />
        </div>

        <div className="form-group">
          <label htmlFor="tags">Tags</label>
          <input
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            id="tags"
            className="form-control"
            type="text"
          />
        </div>

        <div className="form-group">
          <label htmlFor="details">Source</label>
          <textarea
            value={source}
            onChange={(e) => setSource(e.target.value)}
            id="details"
            className="form-control"
            rows="4"
          ></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="details">Details</label>
          <textarea
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            id="details"
            className="form-control"
            rows="4"
          ></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="samples">Samples</label>
          <input
            value={samples}
            onChange={(e) => setSamples(e.target.value)}
            id="samples"
            className="form-control"
            type="text"
          />
        </div>

        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default UpdateDashboard;
