import React, { useState, useContext, useEffect } from "react";
import { DashboardContext } from "../context/DashboardContext";
import ImageUpload from "./ImageUpload";
import DashboardFinder from "../apis/DashboardFinder";

const Row = ({ index, data, handleChange }) => (
  <div key={index} style={{ display: "flex", marginBottom: "10px" }}>
    <input
      className="form-control"
      type="text"
      name="key"
      value={data.key}
      placeholder="Key"
      onChange={(e) => handleChange(index, e)}
    />
    <input
      className="form-control"
      type="text"
      name="value"
      value={data.value}
      placeholder="Value"
      onChange={(e) => handleChange(index, e)}
    />
  </div>
);

const AddDashboard = () => {
  const { addDashboards } = useContext(DashboardContext);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [stack, setStack] = useState("");
  const [tags, setTags] = useState("");

  const [search_vector, setSearch_vector] = useState("");
  const [details, setDetails] = useState("");
  const [source, setSource] = useState("");
  const [samples, setSamples] = useState("");
  const [jsonString, setJsonString] = useState("");

  const [csvInput, setCsvInput] = useState("");
  const [imageFiles, setImageFiles] = useState();

  const [previews, setPreviews] = useState();

  const [rows, setRows] = useState([{ key: "", value: "" }]);
  const [showTools, setShowTools] = useState(false);

  const handleImageUpload = (e) => {
    /** setImageFiles([...imageFiles, e]); **/
    setImageFiles(e);
  };

  useEffect(() => {
    console.log("images", imageFiles);
    console.log("previews", previews);
  }, [previews, imageFiles]);

  const handleChange = (index, event) => {
    const newRows = [...rows];
    newRows[index][event.target.name] = event.target.value;
    setRows(newRows);
  };

  const addRow = () => {
    setRows([...rows, { key: "", value: "" }]);
  };

  const handleJsonSubmit = (event) => {
    event.preventDefault();
    const jsonData = rows.reduce((acc, row) => {
      acc[row.key] = row.value;
      return acc;
    }, {});
    console.log("JSON data:", JSON.stringify(jsonData));
    setJsonString(JSON.stringify(jsonData, null, 2));
  };

  const convertCsvToJson = () => {
    const lines = csvInput.split("\n");
    const jsonResult = {};

    lines.forEach((line) => {
      const [key, value] = line.split(",");

      if (key && value) {
        jsonResult[key.trim()] = value.trim();
      }
    });

    setJsonString(JSON.stringify(jsonResult, null, 2));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("trying to add...");
    console.log(imageFiles);

    const tagsArray = tags
      .toString()
      .split(",")
      .map((tag) => tag.trim());
    const tagsArrayLiteral =
      "{" +
      tagsArray.map((tag) => '"' + tag.replace(/"/g, '""') + '"').join(",") +
      "}";

    const formData = new FormData();

    /** imageFiles.forEach((file) => {
      formData.append("images", file);
    }); **/

    formData.append("image", imageFiles);
    formData.append("name", name);
    formData.append("location", location);
    formData.append("stack", stack);
    formData.append("tags", tagsArrayLiteral);
    formData.append("details", details);
    formData.append("source", source);
    formData.append("samples", samples);

    console.log(formData);

    try {
      const response = await DashboardFinder.post("/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("res", response);
      const data = await response.data.data;
      console.log("data", data);
      addDashboards(data.dashboard);
      console.log("Success:", data);

      setName("");
      setLocation("");
      setStack("");
      setSamples("");
      setImageFiles("");
      setSearch_vector("");
      setDetails("");
      setSource("");
      setJsonString("");
      setPreviews("");
      setTags("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCopyJson = () => {
    navigator.clipboard.writeText(jsonString).then(
      () => {
        alert("JSON copied to clipboard");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };
  return (
    <div className="mb-4 container">
      <h4 className="text-left ">Add a Dashboard:</h4>
      <hr style={{ height: "3px", border: "none", backgroundColor: "black" }} />

      <form action="" onSubmit={handleSubmit}>
        <ImageUpload
          onUpload={handleImageUpload}
          preview={previews}
          setPreview={setPreviews}
        />

        <div className="row row-cols-1 row-cols-md-4 g-4">
          <div className="col">
            <label className="form-label small">
              Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Name"
              required
            />
          </div>
          <div className="col">
            <label className="form-label small">
              Project<span style={{ color: "red" }}>*</span>
            </label>
            <input
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Project"
              required
            />
          </div>
          <div className="col">
            <label className="form-label small">Stack</label>
            <input
              value={stack}
              onChange={(e) => setStack(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Stack"
            />
          </div>
          <div className="col">
            <label className="form-label small">
              Tags<span style={{ color: "red" }}>*</span>
            </label>
            <input
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              type="text"
              className="form-control"
              placeholder="tag1, tag2, tag3"
              required
            />
          </div>
          <div className="col">
            <label className="form-label small">Search Vector</label>
            <input
              value={search_vector}
              onChange={(e) => setSearch_vector(e.target.value)}
              type="text"
              className="form-control"
              placeholder="`OA Funnel User Trends` || ` ` || `OneApp`"
            />
          </div>
          <div className="col">
            <label className="form-label small">Details</label>
            <textarea
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Details"
            />
          </div>
          <div className="col">
            <label className="form-label small">Source</label>
            <input
              value={source}
              onChange={(e) => setSource(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Source"
            />
          </div>
          <div className="col">
            <label className="form-label small">Samples</label>
            <input
              value={samples}
              onChange={(e) => setSamples(e.target.value)}
              type="text"
              className="form-control"
              placeholder='{"new_key":"new_value"}'
              required
            />
          </div>
        </div>
        <div className="mt-4 d-grid gap-2 d-md-flex justify-content-md-end">
          <button type="submit" className="btn btn-success me-md-2">
            Add Dashboard
          </button>
        </div>
      </form>

      <div className="d-grid d-md-flex justify-content-md-start pb-4">
        <button
          onClick={() => setShowTools(!showTools)}
          type="button"
          className="btn btn-primary me-md-2"
        >
          {!showTools ? "Show Tools" : "Hide Tools"}
        </button>
      </div>
      {showTools && (
        <div>
          <h5 className="text-left pt-8">JSON Tools:</h5>
          <hr
            style={{ height: "3px", border: "none", backgroundColor: "black" }}
          />
          <div className="mt-4">
            <h6 className="mb-2">CSV Input:</h6>
            <textarea
              value={csvInput}
              onChange={(e) => setCsvInput(e.target.value)}
              className="form-control"
              rows="5"
              placeholder="key,value"
            />
            <button onClick={convertCsvToJson} className="btn btn-primary mt-2">
              Convert CSV to JSON
            </button>
          </div>

          <br />
          <h6 className="mb-2">Manual Input:</h6>
          <form onSubmit={handleJsonSubmit}>
            {rows.map((row, index) => (
              <Row
                key={rows[index]}
                index={index}
                data={row}
                handleChange={handleChange}
              />
            ))}
            <button
              className="btn btn-secondary"
              type="button"
              onClick={addRow}
            >
              Add Row
            </button>
            <button className="btn btn-primary" type="submit">
              Get Output
            </button>
          </form>
          <div className="mt-4 position-relative">
            <h6 className="mb-2">JSON Output</h6>
            <textarea
              readOnly
              value={jsonString}
              className="form-control"
              rows="5"
            />
            <button
              onClick={handleCopyJson}
              className="btn btn-secondary position-absolute top-0 end-0 py-0 px-2"
              style={{ fontSize: "0.8rem" }}
            >
              <i className="fas fa-copy"></i> Copy JSON
            </button>
          </div>
          <hr
            style={{ height: "3px", border: "none", backgroundColor: "black" }}
          ></hr>
        </div>
      )}
    </div>
  );
};

export default AddDashboard;
